<template>
	<div id="meaone" class="" style="height: 100%;">
		<div style="padding: 10px 10px;" class="bodyheight d-flex">
			<div style="width: 70%;">
				<div class="d-flex" style="height: 48%;">
					<div style="width: 50%;padding: 15px 15px;" class="background-fff">
						<div class="d-flex align-items-center">
							<div class="tick"></div>
							<span class="fontsize" style="margin-left: 8px;">待办事项</span>
						</div>
						<div class="d-flex flex-row justify-content-between" style="height: 30px;">
							<div></div>
							<el-radio-group v-model="radio" size="mini" @change="changeDB">
								<el-radio-button label="1">待审批</el-radio-button>
								<el-radio-button label="2">已审批</el-radio-button>
							</el-radio-group>
						</div>
						<div class="overflow-auto topheight" style="">
							<div class="d-flex flex-row justify-content-between"
								style="font-size:calc(100vw * 14 / 1920);padding: 10px 10px 10px 0;"
								v-for="(item,index) in DBdata" :key="index">
								<div style="min-width: 80px;">
									{{item.name}}
								</div>
								<div class="d-flex">
									<div v-if="radio == '1'" style="color: #9c9a9a;">待办</div>
									<div v-if="radio == '2'" style="color: #9c9a9a;">已办</div>
									<div class="text-center d-flex" style="min-width: 90px;">
										<div class="text-right" style="color: red;width: 40px;">{{item.value}}</div>笔
									</div>
									<div style="color: #279ddb;cursor: pointer;" @click="toOA(item)">查看</div>
								</div>
							</div>
						</div>
					</div>
					<div style="width: 50%;padding: 15px 15px;margin: 0 10px;" class="background-fff">
						<div class="d-flex align-items-center">
							<div class="tick"></div>
							<span class="fontsize" style="margin-left: 8px;">公告</span>
						</div>
						<div class="d-flex flex-row justify-content-between" style="height: 30px;">
							<div></div>
							<el-radio-group v-model="radio2" size="mini" @change="changeGG">
								<el-radio-button label="1">公告通知</el-radio-button>
								<el-radio-button label="2">任务通知</el-radio-button>
							</el-radio-group>
						</div>
						<div class="overflow-auto topheight" style="">
							<div v-if="radio2=='1'" class="d-flex flex-row justify-content-between"
								style="font-size:calc(100vw * 14 / 1920);padding: 10px 10px 10px 0;"
								v-for="(item,index) in gonggaoData" :key="index">
								<div style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 200px;">
									{{item.title}}
								</div>
								<div style="color: #279ddb;cursor: pointer;" @click="toOA(item)">查看</div>
							</div>
						</div>
					</div>
				</div>
				<div class="background-fff"
					style="height: 30%;margin-top: 10px;margin-right: 10px;padding: 0px 25px 20px 25px;">
					<div class="d-flex align-items-center" style="padding: 20px 0;">
						<div class="tick"></div>
						<span class="fontsize" style="margin-left: 8px;">今日和昨日计划</span>
					</div>
					<div class="d-flex footerhtight">
						<div class="h-100" style="width: 33%;background-color: #eff7fc;">
							<div class="d-flex" style="padding: 18px 15px 0 15px;">
								<div style="width:20px;height:20px;"><img style="width: 100%;"
										src="../../assets/images/fixed.png" alt=""></div>
								<span class="fontsize" style="margin-left: 10px;">商务活动</span>
							</div>
							<div class="cloum">
								<div style="margin-left: 25px;">
									暂无
								</div>
							</div>
						</div>
						<div class="h-100" style="width: 33%;background-color: #eff7fc;margin: 0 15px;">
							<div class="d-flex" style="padding: 18px 15px 0 15px;">
								<div style="width:20px;height:20px;"><img style="width: 100%;"
										src="../../assets/images/fixed.png" alt=""></div>
								<span class="fontsize" style="margin-left: 10px;">今日安排</span>
							</div>
							<div class="cloum">
								<div style="margin-left: 25px;" v-html="backday">
								</div>
							</div>
						</div>
						<div class="h-100" style="width: 33%;background-color: #eff7fc;">
							<div class="d-flex" style="padding: 18px 15px 0 15px;">
								<div style="width:20px;height:20px;"><img style="width: 100%;"
										src="../../assets/images/fixed.png" alt=""></div>
								<span class="fontsize" style="margin-left: 10px;">昨日工作</span>
							</div>
							<div class="cloum">
								<div style="margin-left: 25px;" v-html="nowday">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="d-flex flex-column background-fff rightheight" style="padding: 10px 10px 20px 10px;width: 30%;">
				<div style="height: 70%;">
					<div class="d-flex align-items-center">
						<div class="tick"></div>
						<span class="fontsize" style="margin-left: 8px;">备忘录</span>
					</div>
					<div class="background-fff caleddar">
						<Calendar v-on:choseDay="clickDay" v-on:changeMonth="changeDate" v-on:isToday="clickToday">
						</Calendar>
					</div>
				</div>
				<div class="diabody d-flex flex-row" style="background-color: #cae9fa;padding: 10px 0;border-radius: 5px;margin-top: 22px;height: 30%;">
					<div class="d-flex flex-column h-100 w-50" style="padding: 0 25px;">
						<div class="fontsizeall d-flex align-items-center" style="color: #279ddb;font-weight: 550;height: 50%;white-space:nowrap;">
							移到红点处显示备忘信息
						</div>
						<div style="height: 50%;" @click="addmemorandum">
							<button class="fontsizeall" style="border: none;background-color: #fff;border-radius: 20px;color: #279ddb;padding: 5px 20px;">添加备忘录</button>
						</div>
					</div>
					<div class="w-50 h-100 d-flex flex-row justify-content-between">
						<div></div>
						<img style="width: 80%;object-fit: contain;" src="../../assets/images/user.png" alt="">
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="mt-3 d-flex flex-column" style="height: calc(100% - 53px);">
			<div class="d-flex flex-row justify-content-between" style="height: 60%;">
				<div class="bigmodelStyle py-4 ">
					<div class="bigmodeltitle px-5">
						待办事项
					</div>
					<div class="text-right px-5">
						<el-radio-group v-model="radio" size="mini" @change="changeDB">
							<el-radio-button label="1">待审批</el-radio-button>
							<el-radio-button label="2">已审批</el-radio-button>
						</el-radio-group>
					</div>
					<div class="overflow-auto px-5" style="min-height: 180px;height:80%;">
						<div class="d-flex flex-row justify-content-between py-2 border-bottom"
							style="font-size:calc(100vw * 14 / 1920) ;" v-for="(item,index) in DBdata" :key="index">
							<div class="">
								{{item.name}}
							</div>
							<div class="d-flex">
								<div v-if="radio == '1'" style="color: #9c9a9a;">待办</div>
								<div v-if="radio == '2'" style="color: #9c9a9a;">已办</div>
								<div class="pr-4 text-center d-flex">
									<div class="text-right" style="color: red;width: 40px;">{{item.value}}</div>笔
								</div>
								<div style="color: #409eff;cursor: pointer;" @click="toOA(item)">查看</div>
							</div>
						</div>
					</div>

				</div>
				<div class="bigmodelStyle py-4">
					<div class="bigmodeltitle px-5">
						公告
					</div>
					<div class="text-right px-5">
						<el-radio-group v-model="radio2" size="mini" @change="changeGG">
							<el-radio-button label="1">公告通知</el-radio-button>
							<el-radio-button label="2">任务通知</el-radio-button>
						</el-radio-group>
					</div>
					<div class="overflow-auto px-5" style="min-height: 180px;height: 80%;">
						<div v-if="radio2=='1'" class="d-flex flex-row justify-content-between py-2 border-bottom"
							style="font-size:calc(100vw * 14 / 1920) ;" v-for="(item,index) in gonggaoData"
							:key="index">
							<div style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 200px;">
								{{item.title}}
							</div>
							<div class="pl-4" style="color: #409eff;cursor: pointer;" @click="toOA">查看</div>
						</div>
					</div>

					<div v-if="gonggaoData.length == 0" style="font-size: 12px;color: #9c9a9a;" class="text-center">暂无数据
					</div>
				</div>
				<div class="bigmodelStyle py-4 px-5" style="overflow: hidden;">
					<div class="bigmodeltitle">
						备忘录
					</div>
					<div>
						<el-calendar v-model="value">
						</el-calendar>
					</div>
				</div>
			</div>
			<div class="pt-3" style="height: 40%;">
				<div class="pb-3" style="font-size: calc(100vw * 24 / 1920);">
					昨日任务和今日计划
				</div>
				<div class="d-flex flex-row justify-content-between" style="height: 75%;">
					<div class="someboxstyle d-flex flex-row">
						<div style="background-color: #409eff;padding: 0 calc(100vw * 7 / 1920);"></div>
						<div class="d-flex flex-column pl-3">
							<div class="py-3" style="">
								<span style="font-size: 16px;">今日：</span><i class="el-icon-alarm-clock"></i><span
									class="pl-2">{{nowdate}}</span>
							</div>
							<div class="pt-3 pr-3" v-html="backday">
							</div>
						</div>
					</div>
					<div class="someboxstyle d-flex flex-row">
						<div style="background-color: #f78f4d;padding: 0 calc(100vw * 7 / 1920);"></div>
						<div class="d-flex flex-column pl-3">
							<div class="py-3" style="">
								<span style="font-size: 16px;">昨日：</span><i class="el-icon-alarm-clock"></i><span
									class="pl-2">{{backdate}}</span>
							</div>
							<div class="pt-3 pr-3" v-html="nowday">
							</div>
						</div>
					</div>
					<div style="width: 32%;"></div>
				</div>
			</div>
		</div> -->
		

	</div>
</template>

<script>
	import Calendar from 'vue-calendar-component';
	import Cookies from 'js-cookie'
	export default {
		components: {
			Calendar
		},
		data() {
			
			return {
				radio: '1',
				radio2: '1',
				userData: null,
				data:null,
				DBdata: [],
				gonggaoData: [],
				value: new Date(),
				nowday: "",
				backday: '',
				nowdate: '',
				backdate: '',
				
			}
		},
		mounted() {
			this.getuserinfor()
			this.queryMission()
			// this.getToken()
			// this.getNowDate()
			// setTimeout(() => {
				// this.getOaDaiban()
			// 	this.queryAnnounce()
			// 	this.queryMemo()
			// 	this.currentDateBack()
			// }, 1500)
			// setTimeout(() => {
				// this.getuserinfor()
			// }, 3000)
		},
		methods: {
			clickDay(data) {
				console.log(data); //选中某天
			},
			changeDate(data) {
				console.log(data); //左右点击切换月份
			},
			clickToday(data) {
				console.log(data); // 跳到了本月
			},
			//菜单权限
			getToken() {
				let that = this
				Cookies.remove('router', {
					domain: '.tofobao.com'
				})
				that.$axios.get('rbac/v1/getAuthListBy?abbr=YX').then((
					res) => {
					let listarr = []
					listarr = res.data.data.map(item => {
						return item.path
					})
					Cookies.set('router', listarr[0], {
						domain: '.tofobao.com'
					})
				})
				console.log(Cookies.get('userId'));
			},
			getNowDate() {
				var myDate = new Date(); //创建Date对象
				var Y = myDate.getFullYear(); //获取当前完整年份
				var M = myDate.getMonth() + 1; //获取当前月份
				var D = myDate.getDate(); //获取当前日1-31
				// 月份不足10补0
				if (M < 10) {
					M = '0' + M;
				}
				// 日不足10补0
				if (D < 10) {
					D = '0' + D;
				}
				this.nowdate = Y + '-' + M + '-' + D;

				var myDate1 = new Date(new Date().setHours(0) - 24 * 60 * 60 * 1000); //创建Date对象
				var Y1 = myDate1.getFullYear(); //获取当前完整年份
				var M1 = myDate1.getMonth() + 1; //获取当前月份
				var D1 = myDate1.getDate(); //获取当前日1-31
				// 月份不足10补0
				if (M1 < 10) {
					M1 = '0' + M1;
				}
				// 日不足10补0
				if (D1 < 10) {
					D1 = '0' + D1;
				}
				// 拼接日期分隔符根据自己的需要来修改
				this.backdate = Y1 + '-' + M1 + '-' + D1;
			},
			// 获取昨天任务信息
			currentDateBack() {
				this.$axioss.post('administrative/business/selectLogByCurrentDate').then(res => {
					console.log(res);
					if (res.data != null) {
						this.nowday = res.data.htmlContent
						this.backday = res.data.tomorrowPlan
					}
				})
			},
			// 备忘录
			queryMemo() {
				this.$axioss.post('user/memo/queryMemo', {
					currentTime: new Date() * 1
				}).then(res => {

				})
			},
			toOA(val) {
				console.log(val);
				if (val.name == '销售合同') {
					Cookies.set('windowloacl', 'create', {
						domain: '.tofobao.com'
					})
					Cookies.set('contractRouter', val.routeNameRouter, {
						domain: '.tofobao.com'
					})
					Cookies.set('contractActive', val.table, {
						domain: '.tofobao.com'
					})
				} else if (val.name == '采购合同') {
					Cookies.set('windowloacl', 'create', {
						domain: '.tofobao.com'
					})
					Cookies.set('contractRouter', val.routeNameRouter, {
						domain: '.tofobao.com'
					})
					Cookies.set('contractActive', val.table, {
						domain: '.tofobao.com'
					})
				} else if (val.name == '零星劳务') {
					Cookies.set('windowloacl', 'create', {
						domain: '.tofobao.com'
					})
					Cookies.set('contractRouter', val.routeNameRouter, {
						domain: '.tofobao.com'
					})
					Cookies.set('contractActive', val.table, {
						domain: '.tofobao.com'
					})
				}else if(val.name == '采购申请'){
					Cookies.set('purchaseRouter', val.routeNameRouter, {
						domain: '.tofobao.com'
					})
					Cookies.set('purchaseTable', val.table, {
						domain: '.tofobao.com'
					})
				}
				window.open(val.routeName, "_blank");
				// Cookies.set('tabactive', val.table, {
				// 	domain: '.tofobao.com'
				// })
				// Cookies.set('routers', val.routeNameRouter, {
				// 	domain: '.tofobao.com'
				// })
			},
			// 切换公告
			changeGG() {
				if (this.radio2 == '1') {
					this.queryAnnounce()
				}
				if (this.radio2 == '2') {
					this.queryMission()
				}
			},
			// 公告-任务通知
			queryMission() {
				this.$axioss.post('administrative/mission/queryMission').then(res => {
					if (res.status == 200) {
						this.gonggaoData = res.data
					}
				})
			},
			// 公告-公告通知
			queryAnnounce() {
				this.$axioss.post('administrative/announcenment/queryAnnounce').then(res => {
					if (res.status == 200) {
						this.gonggaoData = res.data.records
					}
				})
			},
			// 切换代办审批
			changeDB() {
				this.getuserinfor()
			},
			// 获取用户信息
			getuserinfor() {
				
				this.$axios.get('user/v1/getCurrentUserDetail').then(res => {
					if (res.data.code == '1') {
						// console.log(res.data.data);
						this.userData = res.data.data
						this.getOaDaiban(res.data.data)
						sessionStorage.setItem('userdata', JSON.stringify(res.data.data))
						// console.log(sessionStorage.getItem('userdata'));
						Cookies.set('userData', JSON.stringify(res.data.data), {
							domain: '.tofobao.com'
						})	
						Cookies.set('userId', res.data.data.id, {
							domain: '.tofobao.com'
						})
						// this.getList(res.data.data.id)
					}
				})
			},
			// 代办审批数据
			getOaDaiban(val) {	
				console.log(val);
				this.$axios.get('marketing/v1/approvalCountStatistic?activeUserId=' + val.id + '&status=' +
					this
					.radio).then(res => {
					if (res.status == 200) {
						console.log(res);
						this.DBdata = res.data.data
					}
				})
			},
			// 获取用户信息
			// getuserinfor() {
			// 	this.userData = sessionStorage.getItem('userData')
			// 	console.log(this.userData);
			// },
			
			//添加备忘录
			addmemorandum(){
				this.$message.info('暂未开发，请耐心等待')
			},
			
		}
	}
</script>

<style scoped="scoped">
	.fontsize {
		font-size: calc(100vw * 17 / 1920) !important;
		/* font-weight: 100; */
	}
	.fontsizeall {
		font-size: calc(100vw * 16 / 1920) !important;
		/* font-weight: 100; */
	}

	.tick {
		width: 5px;
		height: 21px;
		background-color: #1c95d4;
	}

	.background-fff {
		background-color: #fff;
	}

	.bodyheight {
		height: calc(100% - 60px);
	}

	.topheight {
		height: calc(100% - 51px);
	}

	.footerhtight {
		height: calc(100% - 64px);
	}
	.cloum{
		height: calc(100% - 42px);
		overflow-y: auto;
	}
	.caleddar {
		height: calc(100% - 21px);
	}

	.right-height {
		height: calc(73% + 10px);
	}
	.rightheight{
		height: calc(78% + 10px);
	}
	.diabody{
		height: 15vh;
	}
	.someboxstyle {
		box-shadow: 0 0rem 1rem rgb(0 0 0 / 10%) !important;
		width: 32%;
		height: 100%;
		min-height: 100px;
		border-radius: 20px;
		overflow-y: auto;
	}

	/deep/.el-calendar-table:not(.is-range) td.next,
	.el-calendar-table:not(.is-range) td.prev {
		color: #C0C4CC;
	}

	/deep/.el-calendar-table td.is-selected {
		background-color: #e0e5ea;
	}

	/deep/.el-calendar-table .el-calendar-day:hover {
		background-color: #e0e5ea;
	}

	/deep/.el-calendar {
		background-color: #f6f9fd;
	}

	/deep/.el-calendar-table .el-calendar-day {
		height: calc(100vw * 18 / 1080) !important;
	}

	.bigmodeltitle {
		font-size: calc(100vw * 22 / 1920) !important;
	}

	.bigmodelStyle {
		box-shadow: 0 0rem 1rem rgb(0 0 0 / 10%) !important;
		width: 32%;
		height: 100%;
		min-height: 280px;
		border-radius: 20px;
	}

	.xiaoxistyle {
		background-color: #1c95d4;
		border-radius: 20px;
		padding: 5px 10px;
		font-size: 14px;
		font-weight: bold;
		color: white;
		cursor: pointer;
	}

	.xiaoxistyle span {
		color: white;
	}

	*,
	html,
	body,
	p {
		color: black;
		/* font-family: '宋体'; */
	}

	/deep/.el-radio-button:first-child .el-radio-button__inner {
		border-left: 0px;
	}

	/deep/.el-radio-button__inner {
		background-color: #eff3fb;
		border-color: #eff3fb;
	}

	/deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner {
		background-color: #1c95d4;
		border-color: #1c95d4;
	}

	#meaone {
		/* background: linear-gradient(-90deg, #20AFB1 0%, #1787C6 100%); */
		background-color: #f6f9fd;
	}

	/deep/.el-button-group {
		display: flex;
	}

	/deep/.el-calendar-day {
		font-weight: 300;
	}

	/* /deep/.wh_container{
		max-width: 100%;
		margin: 0;
	} */
	/deep/.wh_content_all {
		background-color: #e8f4fb;
		height: 100%;
		overflow-y: auto;
	}
	/deep/.wh_content_all[data-v-2ebcbc83] {
		background-color: #e8f4fb;
		height: 100%;
		overflow-y: auto;
	}
	
	/deep/.wh_content_item {
		color: black;
	}

	/deep/.wh_top_changge li {
		color: black;
		flex: 3;
		display: flex;
		font-size: calc(100vw * 17 / 1920) !important;
		/* font-size: 14px; */
	}

	/deep/.wh_jiantou1[data-v-2ebcbc83]{
		width: 12px;
		height: 12px;
		border-top: 2px solid #ccc;
		border-left: 2px solid #ccc;
		transform: rotate(-45deg);
	}

	/deep/.wh_jiantou2[data-v-2ebcbc83] {
		width: 12px;
		height: 12px;
		border-top: 2px solid #ccc;
		border-right: 2px solid #ccc;
		transform: rotate(45deg);
	}

	/deep/.wh_content_item .wh_isToday {
		background: #279ddb;
		border-radius: 100px;
		color: white;
	}
	/deep/.wh_content_item .wh_isToday[data-v-2ebcbc83] {
		background: #279ddb;
		border-radius: 100px;
		color: white;
	}
	/deep/.wh_top_changge li[data-v-2ebcbc83]{
		color: black;
	}
	/deep/.wh_content_item[data-v-2ebcbc83], wh_content_item_tag[data-v-2ebcbc83]{
		color: black;
	}
	/deep/.wh_item_date[data-v-2ebcbc83]:hover {
		cursor: pointer;
		background-color: #29bfff;
	}

	/deep/.wh_content_item .wh_chose_day[data-v-2ebcbc83] {
		background: #29bfff;
		border-radius: 100px;
	}
	/deep/.wh_item_date:hover{
		background: #29bfff;
		border-radius: 100px;
	}
	/deep/.wh_container{
		max-width: 100%;
		margin: 0;
		height: 100%;
	}
	/deep/.wh_container[data-v-2ebcbc83]{
		max-width: 100%;
		margin: 0;
		height: 100%;
	}
	/deep/.wh_top_changge{
		padding: 10px 0;
		background-color: white;
	}
	/deep/.wh_content[data-v-2ebcbc83]{
		padding: 5px 3%;
	}
</style>
<style>
	.el-message .el-icon-info {
		font-size: 20px;
	}

	.wh_content {
		margin-left: 18px;
		background-color: #e8f4fb;
	}
</style>
