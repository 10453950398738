<template>
	<div id="login" class="d-flex align-items-center justify-content-center bg-light">
		<div class="d-flex flex-row px-4 text-center shadow" style="width: 68.9vw;height: 62.13vh;min-width: 600px;min-height: 500px;background: linear-gradient(0deg, #0505B8 0%, #085156 100%);
border-radius: 20px;opacity: 0.9;">
			<div class="w-50 h-100 d-flex align-items-center justify-content-center">
				<div class="">
					<img src="../assets/images/login_logo.png" width="90%" alt="">
				</div>
			</div>
			<div class="w-50 h-100 d-flex flex-column align-items-center justify-content-center">
				<div
					style="font-size: 28px;font-weight: bold;color: #3CADFD;background: linear-gradient(0deg, #2F81ED 0%, #2EE9D8 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;">
					Hello,欢迎登录后台</div>
				<div class="d-flex flex-row align-items-center"
					style="color: #F6F7FB;font-weight: bold;font-size: 20px;margin: 15px 0;">托付保·软件集成系统</div>
				<div class="mb-5">
					<div v-if="blean == false">
						<div class="">
							<el-input v-model="username" style="width: 18vw;min-width: 300px;" placeholder="请输入后台账号"
								clearable></el-input>
						</div>
						<div style="margin-top: 10px;">
							<el-input placeholder="请输入登录密码 " v-model="password" show-password clearable></el-input>
						</div>
					</div>
					<div v-if="blean == true">
						<div class="">
							<el-input v-model="phone" style="width: 18vw;min-width: 300px;" placeholder="请输入手机号"
								clearable></el-input>
						</div>
						<div class="d-flex" style="margin-top: 10px;">
							<input type="text" class="code-input" v-model="code">
							<el-button class="c-btn" @click="add" :disabled="disabled">
								{{codeTime == 60?"获取验证码":codeTime != 60?codeTime+"秒后重试":""}}
							</el-button>
						</div>
					</div>
					<p style="margin: 10px 0;" class="code" @click="blean = !blean">
						{{blean == true?"切换为账号登录":"切换为验证码登录"}}
					</p>
				</div>
				<div class="pt-t">
					<el-button @click="login" @keyup.enter.native="enterLogin()"
						style="background: linear-gradient(0deg, #2F81ED, #2EE9D8);width: 18vw;min-width: 200px;border-radius: 100px;color: #FFFFFF;font-size: 16px;font-weight: bold;">
						登 录</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import store from "store";
	import Cookies from 'js-cookie'
	export default {
		data() {
			return {
				username: "",
				password: '',
				blean: false,
				phone: "",
				code: "",
				codeTime: 60,
				disabled: false
			}
		},
		mounted() {

		},
		created() {
			this.enterLogin();
			if (Cookies.get('returnLogin', {
					domain: '.tofobao.com'
				}) == 'one') {
				Cookies.remove('userId', {
					domain: '.tofobao.com'
				})
				this.$router.go(0)
			}

		},
		methods: {
			enterLogin() {
				document.onkeydown = (e) => {
					e = window.event || e;
					if (e.code == 'Enter' || e.code == 'enter') {
						//调用登录事件方法
						this.login();
					}
				}
			},

			login() {
				let that = this
				let index = isNaN(this.username)
				if (this.blean) {
					this.$axios.get('user/v1/getAuthToken?code=' + this.code + '&mobile=' + this.phone).then(res => {
						if (res.data.code == '1') {
							that.$message.success('登录成功');
							Cookies.set('token', JSON.stringify(res.data.data.authToken), {
								domain: '.tofobao.com'
							})
							Cookies.set('creatego', 'create', {
								domain: '.tofobao.com'
							})
							Cookies.set('createto', 'creates', {
								domain: '.tofobao.com'
							})
							sessionStorage.setItem('token', JSON.stringify(res.data.data.authToken))
							Cookies.remove('tologin', {
								domain: '.tofobao.com'
							})
							that.$router.push({
								name: 'Meaone'
							})
						}
					})
				} else {
					if (index) {
						this.$axios.get('user/v1/getAuthToken?password=' + this.password + '&username=' + this.username +
								'&login=login')
							.then(res => {
								if (res.data.code == '1') {
									console.log(res);
									that.$message.success('登录成功');
									Cookies.set('token', JSON.stringify(res.data.data.authToken), {
										domain: '.tofobao.com'
									})
									Cookies.set('creatego', 'create', {
										domain: '.tofobao.com'
									})
									Cookies.set('createto', 'creates', {
										domain: '.tofobao.com'
									})

									sessionStorage.setItem('token', JSON.stringify(res.data.data.authToken))
									Cookies.remove('tologin', {
										domain: '.tofobao.com'
									})
									that.$router.push({
										name: 'Meaone'
									})

									// setInterval(() => this.newToken(), 900000);
								}

							})
					} else {
						this.$axios.get('user/v1/getAuthToken?password=' + this.password + '&mobile=' + this.username)
							.then(res => {
								if (res.data.code == '1') {
									that.$message.success('登录成功');
									Cookies.set('token', JSON.stringify(res.data.data.authToken), {
										domain: '.tofobao.com'
									})
									Cookies.set('creatego', 'create', {
										domain: '.tofobao.com'
									})
									Cookies.set('createto', 'creates', {
										domain: '.tofobao.com'
									})
									sessionStorage.setItem('token', JSON.stringify(res.data.data.authToken))
									Cookies.remove('tologin', {
										domain: '.tofobao.com'
									})
									that.$router.push({
										name: 'Meaone'
									})
								}
							})
					}
				}

			},
			add() {
				this.$axios.post('third/v1/sms/sendCode', {
					mobile: this.phone
				}).then(res => {
					console.log(res);
				})
				this.disabled = true
				setInterval(() => {
					this.codeTime--
					if (this.codeTime <= 0) {
						this.codeTime = "60"
						this.disabled = false
					}
				}, 1000);

			},
			// newToken() {
			// 	let token = JSON.parse(sessionStorage.getItem('token'))
			// 	if(token !== ""){
			// 		// sessionStorage.removeItem('token')
			// 		let that = this
			// 		// console.log(token);
			// 		this.$axios.get('user/v1/refreshAuthToken?oldAuthToken=' + token).then(res => {
			// 			console.log(res);
			// 			Cookies.set('token', JSON.stringify(res.data.data.authToken), {
			// 				domain: '.tofobao.com'
			// 			})
			// 			Cookies.remove('tologin', {
			// 				domain: '.tofobao.com'
			// 			})
			// 			// that.$router.push({
			// 			// 	name: 'Meaone'
			// 			// })
			// 			sessionStorage.setItem('token', JSON.stringify(res.data.data.authToken))
			// 		})
			// 	}
			// }
		}
	}
</script>

<style scoped='scoped'>
	*,
	html,
	body,
	p {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		font-family: Microsoft YaHei;
	}

	#login {
		width: 100vw;
		height: 100vh;
		background: linear-gradient(-90deg, #20AFB1 0%, #1787C6 100%);
		/* background: url("../assets/images/login.png"); */
		position: relative;
	}

	/deep/.el-input--suffix .el-input__inner {
		border-radius: 100px !important;
		height: 6vh !important;
	}

	/deep/.el-button {
		border: none !important;
		height: 6vh !important;
	}
</style>